import React from "react";
import { contactHolder } from "./contact.module.scss";

export default function ContactPage() {
  return (
    <article className={contactHolder}>
      <h1>Contact</h1>
      <section>
        <p>Contact Girls Girls Girls at</p>
        <p>
          <a href="mailto:wearegirlsgirlsgirls@gmail.com" title="Email Girls Girls Girls">
            wearegirlsgirlsgirls@gmail.com
          </a>
        </p>
      </section>
    </article>
  );
}
